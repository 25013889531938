<template>
    <div class="cursor-pointer flex items-center user_card_drawer justify-between">
        <Profiler
            :avatarSize="28"
            nameClass="text-sm"
            popoverText="Автор"
            initStatus
            :user="user" />
        <div class="flex items-center">
            <a-switch 
                @click="memberType(user)"
                :defaultChecked="user.is_moderator"
                checked-children="Модератор" 
                un-checked-children="Участник" />
            <!-- <a-button 
                @click="memberDelete(user)"
                class="ml-1"
                type="ui"
                flaticon
                ghost 
                icon="fi-rr-delete-user" /> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "MeetingUserDrawerCard",
    props: {
        user: {
            type: Object,
            required: true
        },
        memberDelete: {
            type: Function,
            default: () => {}
        },
        memberType: {
            type: Function,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.user_card_drawer{
    padding: 5px;
}
</style>